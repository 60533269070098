import KeyFromLocal from './GetCartKeyFromLocalstorage'

/**
 * Returns the cart key we can use to get a new cart
 * @param context { commit, dispatch, rootGetters, state } Destructured vuex context variable
 * @param user   optional user object that we want a specific cart key for
 * @param loader the function that returns cart_key from localstorage. Defaults to the GetCartKeyFromLocalStorage func
 *               found in the file of the same name but can be overrriden for testing or whatever else
 * @untested
 */
async function FindCartKey({ commit, dispatch, rootGetters, state }, user = null, loader = KeyFromLocal) {
  // console.log("x load cart key");
  let cartKey = null

  // Try and load key from module state
  if (state.cartKey) {
    cartKey = state.cartKey
    // console.log("fetching cart key from state", cartKey);
  }

  // Try and load the key from local storage
  if (!cartKey) {
    cartKey = loader()
    // console.log("fetching cart key from localstorage", cartKey);
  }

  // Key not found in localstorage so we fetch a key from server instead
  if (!cartKey) {
    // The cart feature does not support isomorphic rendering.
    // A cart key should never be generated server side.
    // Doing so would unexpectedly overwrite the user's active / client side cart key.
    if (process.server) {
      throw new Error('Cart is inaccessible on the server.')
    }

    let url = '/wp-json/salve/v1/get_cart_key/'

    // add user ID if a user was provided
    if (user) {
      url += '?userId=' + user.user_id
      // console.log("added user to url", url);
    }

    console.log('fetching cart key from server', url)
    const response = await dispatch('http/noAuthGet', { url, data: {} }, { root: true })
    cartKey = response.data
  }

  // Save cart key in module state and localstorage
  commit('SET_CART_KEY', cartKey)
  commit('LOCALSTORAGE_SET_CART_KEY', cartKey)

  // console.log("xFetchCartKey Returning key", cartKey);
  return cartKey
}

export default FindCartKey
