import Delivery from './utilities/delivery'
import { computeLocationSchedule } from '@/store/region/utilities/pickup'
import environment from '@/environment'

export default {
  namespaced: true,

  state: () => ({
    // The promise that gets created when we send the http request. We check for this before sending a second request
    locationPromise: null,

    // The region data we pulled from the server
    locationData: null,

    /* Have we loaded location data */
    locationsLoaded: false
  }),

  getters: {
    /* Returns true if we have loaded locations from server */
    xLocationsDataLoaded(state) {
      return state.locationsLoaded
    },

    /* Returns a list of locations keyed by location id */
    locations(state, getters, rootState, rootGetters) {
      if (!state.locationData) return []

      const userIsEmployee = rootGetters['authuser/xCurrentUserIsEmployee']

      return Object.keys(state.locationData).map((key) => {
        const location = state.locationData[key]

        location.schedule = computeLocationSchedule(location.timezone, location.schedule)

        if (userIsEmployee && environment.STORE_CONFIG.EMPLOYEE_ONLY_PICKUP_LOCATIONS.includes(location.id)) {
          location.has_pick_up = true
        }

        return location
      })
    },

    /* Returns number of locations returned from server */
    locationCount(state, getters) {
      return Object.keys(getters.locations).length
    },

    /**
     * Returns a function that returns all regions for the provided location
     * @returns {*}🤷‍
     */
    xGetRegions(state) {
      // Accepts location ID and returns all regions for that location
      return (locationID) => {
        return state?.locationData?.[locationID]?.regions || []
      }
    },

    /**
     * Returns a function that returns a single region for the provided locationid and regionid
     * @returns {*}🤷‍
     */
    xGetRegion(state, getters) {
      // Accepts location ID and returns all regions for that location
      return (location, region) => {
        if (!(location && region)) {
          return null
        }

        return getters.xGetRegions(location).find((item) => item.id === region)
      }
    },

    /**
     * Finds a region using a provided zipcode
     * @param state
     * @returns {function(*): *}
     */
    xFindRegionFromLocationAndZip(state, getters) {
      return (location, zip) => {
        if (!(location && zip)) {
          return null
        }
        return getters.xGetRegions(location).find((item) => item.addresses.includes(zip)) || null
      }
    },

    /**
     * Find region using zipcode. Always uses homestead location for now
     * @param state
     * @param getters
     * @param rootGetters
     * @returns {null}
     */
    xFindRegionFromZip(state, getters, rootGetters) {
      const location = getters.xCurrentLocationID

      return (zip) => {
        return getters.xFindRegionFromLocationAndZip(location, zip)
      }
    },

    /**
     * Returns the next available delivery date for the specified region
     * @param region a full region object likely returned from the xGetRegion function
     * @param forceDate (dayjs object) force the system to use a specific datetime. If null uses the current date/time
     * @returns {function(*): *}
     */
    xFindNextDeliveryDate(state, getters) {
      return (region, forceDate = null) => {
        if (!region) {
          return null
        }

        return Delivery(region?.schedule || [], forceDate).findNextDeliveryDate()
      }
    },

    /**
     * Computes possible delivery dates in the future
     * @param region a full region object likely returned from the xGetRegion function
     * @param forceDate (dayjs object) force the system to use a specific datetime. If null uses the current date/time
     * @param numDays the number of days into the future to check
     * @returns Array
     */
    xFindPossibleDeliveryDates(state, getters) {
      return (region, numDays, forceDate = null) => {
        if (!region) {
          return null
        }

        return Delivery(region?.schedule || [], forceDate).findPossibleDeliveryDates(numDays)
      }
    },

    /**
     * Returns the location object for a given zipcode
     */
    xFindLocationFromZip(state, getters) {
      return (zip) => {
        let location = null
        if (zip !== null) {
          if (getters.locations) {
            // iterate through locations looking for the zipcode of the current address
            location = getters.locations.find((location) => {
              const findRegion = getters.xFindRegionFromLocationAndZip
              return !!findRegion(location.id, zip)
            })
          } else {
            console.error('no locations found (not loaded yet?')
          }
        }
        let retval = null
        if (location) {
          retval = state?.locationData?.[location.id]
        }
        return retval
      }
    },

    /**
     * Returns the ID of the current flowery physical location used by the user. Defaults to location 2
     */
    xCurrentLocationID(state, getters, rootState, rootGetters) {
      // console.log("xCurrentLocationID: calculating location");

      // if no current location default to 2
      return getters?.xCurrentLocation?.id || 2
    },

    /**
     * Returns the object representing the current location
     */
    xCurrentLocation(state, getters, rootState, rootGetters) {
      const currentZip = rootGetters['user-region/xSelectedAddress']?.zip || null
      return getters.xFindLocationFromZip(currentZip) || null
    },

    xPickupLocations(state, getters) {
      if (!getters.locations) return []
      return getters.locations.filter((location) => location.has_pick_up)
    }
  },

  actions: {
    /**
     * Fetches a list of locations from the server
     */
    async xFetchLocations({ commit, getters, state, dispatch }, args) {
      // Don't send fetch twice unless force is set to true
      if (process.client && state.locationData !== null) return

      const url = '/wp-json/salve/v1/data/locations'
      const response = await dispatch('http/get', { url }, { root: true })
      const data = response?.data

      if (data) {
        commit('SET_LOCATIONS', data)
      }
    }
  },

  mutations: {
    /**
     * Saves the region data we pulled from the server to internal state
     * @param state
     * @param payload
     */
    SET_LOCATIONS(state, payload) {
      // console.log("SETTING LOCATION MUTATE")
      // Convert array into an object with location.id as keys
      const retval = {}
      payload.forEach((item) => {
        retval[item.id] = item
      })
      state.locationData = retval

      // Set a flag that states We have loaded data into the locations module
      state.locationsLoaded = true
    },

    /**
     * Request for location data has been sent to the server. Save the promise in the state of this module
     * @param state
     * @param promise
     * @constructor
     */
    SENT_REQUEST(state, { promise }) {
      // console.log("SENT REQUEST MUTATION", promise);
      state.locationPromise = promise
    }
  }
}
