/**
 * Converts a query object into URL params
 * Currently, if a query key is an array then its a list of selectable fields that can be passed as is once the
 * data has been url encoded. If a query key is an object, then its a range from min to max and should contain
 * two keys named min and max respectively. These values must be converted to url query variables with the
 * structure {{ url }}?min_{{ query key}}={{key.min}}&max_{{ query key }}={{ key.max }}
 *
 * Example:
 * url = www.cats.com
 * query = {
 *   price: {
 *     min: 10,
 *     max: 50,
 *   },
 *   categories: ["potato", "carrot"]
 * }
 *
 * the resulting URL will be
 * min_price=10&max_price=50&categories[]=potato&categories[]=carrot
 *
 * @param query
 * @constructor
 */
export default function QueryToURLParams(query = {}) {
  const params = new URLSearchParams()

  Object.keys(query).forEach((key) => {
    const value = query[key]
    if (Array.isArray(value)) {
      value.forEach((item) => params.append(`${key}[]`, item))
    } else if (typeof value === 'object' && value !== null) {
      params.append(`min_${key}`, value.min)
      params.append(`max_${key}`, value.max)
    } else if (typeof value === 'boolean') {
      params.append(key, value ? '1' : '0')
    } else if (value !== '' && value !== undefined && value !== null) {
      // Handles non-empty strings and numbers
      params.append(key, value)
    }
    // No need to handle empty strings, undefined, or null values as they shouldn't be added to query params
  })

  // This will return a properly encoded query string ready to be used in a URL
  return params.toString()
}
