/**
 * converts 24 hour time string to 12h
 * @param time the time string (example 13:00)
 * @param separator the separator between hour and minutes. Default is a colon (:)
 * @param ampm if true we add AM/PM to the returned string
 * @param ampm if true we remove minutes if they are 0
 */
export default function ToTwelveHour(time, separator = ':', ampm = false, roundMinutes = false) {
  if (!time) {
    return ''
  }

  // Allows us to add AM/PM to the string if necessary
  let footer = ''

  const split = time.split(':')
  split[0] = Number.parseInt(time.split(':')[0])

  if (split[0] > 12) {
    split[0] -= 12

    // Adds AM/PM to the string if needed
    if (ampm) {
      footer = 'PM'
    }
  } else if (ampm) {
    footer = 'AM'
  }

  // Remove "00" minutes from string if that option is set to true
  if (roundMinutes) {
    if (split[1] === '00') {
      split[1] = ''
    }
  }

  // Add AM/PM to the string if that option is set to true
  if (ampm) {
    split[1] = '' + split[1] + footer
  }

  return split.join(separator)
}
